<template>
        <section class="login-main-wrapper">
         <div class="container-fluid pl-0 pr-0">
            <div class="row no-gutters">
               <div class="col-md-12 p-5 full-height">
                  <div class="login-main-left">
                     <div class="text-center mb-5 login-main-left-header pt-4">
                        <img src="../../assets/Sudani-Logo.png" class="img-fluid" alt="LOGO">
                        <h5 class="mt-3 mb-3">اهلا بك في منصة ضحكتنا</h5>
                        <!-- <p>It is a long established fact that a reader <br> will be distracted by the readable.</p> -->
                     </div>
                     <form v-on:submit.prevent="onLogin()">
                        <div class="form-group">
                           <label>رقم الهاتف</label>
                           <input type="text" class="form-control" placeholder="رقم الهاتف" v-model="msisdn" style="border-color: #060909 !important;">
                        </div>
                        <div class="mt-4">
                           <div class="row">
                              <div class="col-12">
                                 <button type="submit" class="btn btn-outline-primary btn-block btn-lg">تسجيل الدخول</button>
                              </div>
                           </div>
                        </div>
                     </form>
                     <div class="text-center mt-5">
                        <p class="light-gray">ليس لديك حساب؟ <a href="">اشتراك</a></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: 'AppLogin',
  data() {
    return {
      msisdn: ""
    };
  },
  methods: {
    async onLogin() {
      const toast = useToast();
      setTimeout(() => this.$router.push({ path: "/" }), 2500);
      toast.success("تم تسجيل الدخول", { timeout: 2500 });
    }
  }
}
</script>

<style>
.img-fluid {
  max-width: 25%;
  height: auto;
}
.pt-4, .py-4 {
  padding-top: 10rem !important;
}
</style>