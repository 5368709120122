<template>
  <app-login/>
</template>

<script>
import AppLogin from '../../components/Users/AppLogin.vue'
export default {
  components: { AppLogin },
  name: 'Logins'
}
</script>

<style>

</style>